<template>
  <div class="page">
    <Navbar isBack="true" :title="'用户协议'"/>
    <div class="main">
      这里是用户协议~
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style scoped>
.main{
  padding: .1rem;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}
</style>